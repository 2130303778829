<template>
    <div class="register">
        <div class="ta-left logo" @click="$router.push({name:'home'})">
            <img src="@/assets/logo.png" alt="">
        </div>
        <form class="form">
            <a-form-item>
                <span class="label" slot="label">{{$t('register.label.account')}}</span>
                <a-input size="large" v-model="address" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('register.label.email')}}</span>
                <a-input size="large" ref="email" v-model="email" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('register.label.password')}}</span>
                <a-input-password size="large" ref="password" v-model="password" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('register.label.referer')}}</span>
                <a-input size="large" v-model="referer" :disabled="disableRefererIpt" />
            </a-form-item>
            <div class="btn-primary mg30" @click="submit">
                {{$t('common.register_btn')}}
            </div>
            <div class="color-gray">
                {{$t('register.already_registered')}} 
                <span @click="goLogin" class="color-primary">{{$t('common.login_btn')}}</span>
            </div>
        </form>
        <!-- <a-form-item>
            <a-button type="primary" :loading="loading" block @click="submit">
                {{$t('common.register_btn')}}
            </a-button>
            <a-button block @click="goLogin">
                {{$t('common.login_btn')}}
            </a-button>
        </a-form-item> -->
      </div>
</template>
<script>

export default {
    name: 'Register',
    data(){
        return {
            referer: this.$route.params.i,
            address: '',
            password: '',
            email: '',
            disableRefererIpt: true,
            loading: false,
        }
    },
    components:{
    },
    mounted(){
        let _this = this;
        let obj = setInterval(async ()=>{
            if (window.walletAddress) {
                clearInterval(obj)
                _this.address = window.walletAddress;
            }
        }, 10);
        if (!_this.referer){
            _this.disableRefererIpt = false
        }
    },
    methods:{
        submit(){
            return;
            // this.loading = true;
            // let _this = this;
            // if(!this.address || !this.referer || !this.password){
            //     this.$message.error(this.$t('register.tips.iptError'));
            //     return;
            // }
            
            // let data = {
            //     address: this.address,
            //     referer: this.referer,
            //     password: this.password
            // };
            // this.$http.call(this.$http.api.register,{data:data}).then(function(res){
            //     let resp = res.data;
            //     if(resp.code=='200'){
            //         _this.$store.commit('setToken', resp.data.token);
            //         // _this.$store.commit('setUserinfo', resp.data.user);
            //         _this.$router.push({name:'home'});
            //     }else{
            //         _this.$message.error(resp.data);
            //     }
            // },function(res){
            //     console.log('something goes wrong...');
            //     console.log(res);
            // }).then(()=>{
            //     _this.loading = false;
            // });
        },
        goLogin(){
            this.$router.push('/login');
        }
    }
}
</script>
<style lang="less" scoped>
.register{
    width: 100%;
    padding: 2rem;
    .logo{
        img{
            height: 30px;
        }
    }
    .form{
        padding-top: 50px;
    }
    .label {
        color: @white;
    }
}
</style>
